import React from 'react'
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl'
import { compose } from 'recompose'
import Img from 'gatsby-image'
import Scrollspy from 'react-scrollspy'
import StickyBox from 'react-sticky-box'

import SEO from '../components/seo'
import withPageContext from '../pageContext'
import withLayout from '../layout'
// import Products from '../components/Products'
import '../styles/products.sass'

export const query = graphql`
    query {
        kruhovy_pohon_trans: file(
            relativePath: { eq: "products/kruhovy_pohon_trans.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 500, maxHeight: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
                fixed(width: 360, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        linearni_pohon_trans: file(
            relativePath: { eq: "products/linearni_pohon_trans.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 500, maxHeight: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
                fixed(width: 360, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        odhlucnovaciKryt: file(
            relativePath: { eq: "products/odhlucnovaci_kryt_trans.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 500, maxHeight: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
                fixed(width: 360, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        rizeniRegulace: file(
            relativePath: { eq: "products/rizeni_regulace_trans.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 500, maxHeight: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
                fixed(width: 360, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        dopravnikTrans: file(
            relativePath: { eq: "products/dopravnik_trans.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 500, maxHeight: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
                fixed(width: 360, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`

// const ProductsPage = ({ intl }) => {
class ProductsPage extends React.Component {
    // const ReferencePage = ({ data, intl }) => {

    constructor(props) {
        super(props)
        this.handleScroll = this.handleScroll.bind(this)
    }

    // componentDidMount() {
    //     $( window ).scroll(function() {
    //         console.log("scroll");
    //       });
    //     //   https://codepen.io/Pustur/pen/mPNoWx
    // }

    handleScroll = (e) => {
        console.log('component scroll')
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            // do something at end of scroll
            console.log('component scroll')
        }
    }

    // console.log(intl)
    render() {
        const { intl } = this.props
        return (
            <React.Fragment>
                <SEO title={intl.formatMessage({ id: 'products.title' })} />
                <main id="products">
                    <div className="container">
                        <div
                            className="row pt-5"
                            style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}
                        >
                            {/* <div
                                className="products-sidebar col-3"
                                onScroll={this.handleScroll}
                            > */}
                            {/* <div className="products-sidebar-fix mt-5 mb-5"> */}
                            <StickyBox
                                offsetTop={145}
                                className="col-3 products-sidebar"
                            >
                                <nav className="">
                                    <Scrollspy
                                        items={[
                                            'kruhove-podavace',
                                            'linearni-podavace',
                                            'predzasobniky',
                                            'odhlucnovaci-kryty',
                                            'rizeni-a-regulace',
                                            'doplnujici-prvky',
                                        ]}
                                        currentClassName="is-current"
                                        scrolledPastClassName="past"
                                        className="list-unstyled"
                                        offset={-100}
                                        onUpdate={(el) => {
                                            console.log(el)
                                        }}
                                    >
                                        <li className="py-1">
                                            <a href="#kruhove-podavace">
                                                <FormattedHTMLMessage id="products.prod1" />
                                            </a>
                                        </li>

                                        {/* <li className="sub">
                                            <a href="#pr1-1">Válcové násypky</a>
                                        </li>
                                        <li className="sub">
                                            <a href="#pr1-1">Kuželové násypky</a>
                                        </li>
                                        <li className="sub">
                                            <a href="#pr1-1">Frézované násypky</a>
                                        </li>
                                        <li className="sub">
                                            <a href="#pr1-1">Kruhové pohony – typ VD</a>
                                        </li>
                                        <li className="sub">
                                            <a href="#pr1-1">Kruhové pohony – typ VD-D</a>
                                        </li> */}

                                        {/* <li className="sub">
                                            <a href="#pr1-1">sub section 1</a>
                                        </li> */}

                                        <li className="py-1">
                                            <a href="#linearni-podavace">
                                                <FormattedHTMLMessage id="products.prod2" />
                                            </a>
                                        </li>
                                        <li className="py-1">
                                            <a href="#predzasobniky">
                                                <FormattedHTMLMessage id="products.prod3" />
                                            </a>
                                        </li>
                                        <li className="py-1">
                                            <a href="#odhlucnovaci-kryty">
                                                <FormattedHTMLMessage id="products.prod4" />
                                            </a>
                                        </li>
                                        <li className="py-1">
                                            <a href="#rizeni-a-regulace">
                                                <FormattedHTMLMessage id="products.prod5" />
                                            </a>
                                        </li>
                                        <li className="py-1">
                                            <a href="#doplnujici-prvky">
                                                <FormattedHTMLMessage id="products.prod6" />
                                            </a>
                                        </li>
                                    </Scrollspy>
                                </nav>
                            </StickyBox>
                            {/* </div> */}
                            {/* </div> */}
                            <div className="products-content col col-lg-9">
                                {/* Kruhove podavace */}
                                <div
                                    id="kruhove-podavace"
                                    className="pr mt-0 pt-0 prod-item text-justify"
                                >
                                    {/* <div className="row prod-header">
                                        <div className="col-8">
                                            <h1 className="pb-3">
                                                <FormattedHTMLMessage id="products.prod1" />
                                            </h1>
                                            <p>
                                                Kruhové vibrační podavače slouží
                                                k orientaci sypaných dílů a
                                                jejich zásobě na předem
                                                definovanou dobu produkce.
                                                Skládají se z násypky, která
                                                může být válcová, kuželová nebo
                                                frézovaná a pohonu umístěného
                                                pod násypkou, který je zdrojem
                                                vibrací a hnací silou dílů. Na
                                                základě velikosti, typu dílu,
                                                požadované hodinové zásoby a
                                                taktu podávání dílů je
                                                zákazníkovi navržen vhodný typ a
                                                velikost kruhové násypky a
                                                pohonu.
                                            </p>
                                        </div>
                                        <div className="col">
                                            <Img
                                                fluid={
                                                    this.props.data.kruhovy_pohon_trans
                                                        .childImageSharp.fluid
                                                }
                                                className=""
                                                alt=""
                                            />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="pb-3">
                                                <FormattedHTMLMessage id="products.prod1" />
                                            </h1>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="float-right p-3">
                                                <Img
                                                    fixed={
                                                        this.props.data
                                                            .kruhovy_pohon_trans
                                                            .childImageSharp
                                                            .fixed
                                                    }
                                                    className=""
                                                    alt=""
                                                />
                                            </div>

                                            <p>
                                                Kruhové vibrační podavače slouží
                                                k orientaci sypaných dílů a
                                                jejich zásobě na předem
                                                definovanou dobu produkce.
                                                Skládají se z násypky, která
                                                může být válcová, kuželová nebo
                                                frézovaná a pohonu umístěného
                                                pod násypkou, který je zdrojem
                                                vibrací a hnací silou dílů. Na
                                                základě velikosti, typu dílu,
                                                požadované hodinové zásoby a
                                                taktu podávání dílů je
                                                zákazníkovi navržen vhodný typ a
                                                velikost kruhové násypky a
                                                pohonu.
                                            </p>

                                            <h3>Válcové násypky</h3>
                                            <p>
                                                Válcové násypky slouží jako
                                                zásoba sypaných dílů a zároveň
                                                jako prvek, ve kterém dochází ke
                                                zorientování dílů pomocí
                                                pozicovacích prvků. Ty zaručí,
                                                že jsou všechny díly na výstupu
                                                z násypky zorientovány do stejné
                                                polohy. Násypky jsou vyrobeny z
                                                plechů různých tlouštěk a
                                                následně svařeny v jeden celek.
                                                Materiál násypek i povrchová
                                                úprava jsou voleny na základě
                                                typu dílů, požadavků na dodávané
                                                množství, prostředí, ve kterém
                                                pracují a dalších potřeb, které
                                                zákazník specifikuje.{' '}
                                            </p>

                                            <h3>Kuželové násypky</h3>
                                            <p>
                                                Kuželové násypky slouží k zásobě
                                                sypaných dílů a jako prvek, ve
                                                kterém dochází ke zorientování
                                                dílů pomocí pozicovacích prvků.
                                                Ty zaručí, že jsou všechny díly
                                                na výstupu z násypky
                                                zorientovány do stejné polohy.
                                                Násypky jsou vyrobeny z plechů
                                                různých tlouštěk a následně
                                                svařeny v jeden celek. Materiál
                                                násypek i povrchová úprava jsou
                                                voleny na základě typu dílů,
                                                požadavků na dodávané množství,
                                                prostředí, ve kterém pracují a
                                                dalších potřeb, které zákazník
                                                specifikuje.{' '}
                                            </p>

                                            <h3>Frézované násypky</h3>
                                            <p>
                                                Frézované násypky kuželového
                                                tvaru jsou do posledního detailu
                                                navrženy ve 3D programu přímo
                                                pro daný díl a obrobeny do
                                                finální podoby. Materiál násypek
                                                je volen dle typu dílu, a to buď
                                                ze slitiny hliníku nebo
                                                polyamidu. Povrch násypek z
                                                hliníkových slitin se po
                                                obrobení chemicko-tepelně
                                                upravuje eloxováním. Stejně jako
                                                u válcových a jiných kuželových
                                                násypek jsou na násypce navržena
                                                místa, do kterých se montují
                                                orientační a výstupní prvky
                                                zaručující správné natočení
                                                všech podávaných dílů. Násypky
                                                je možné zhotovit od průměru 100
                                                mm až po obrobky o průměru 600
                                                mm.
                                            </p>

                                            <h3>Kruhové pohony – typ VD</h3>
                                            <p>
                                                Kruhové pohony typu VD jsou
                                                nejčastěji používané pohony pro
                                                kruhové podavače. Vyznačují se
                                                především jednoduchou
                                                konstrukcí, která umožňuje
                                                snadný přístup ke všem
                                                komponentám. Montážní otvory pro
                                                uchycení k základní desce jsou
                                                přístupné shora, čímž je
                                                usnadněno uchycení ke stroji
                                                nebo jinému pracovnímu místu.
                                                Celý pohon stojí na
                                                silentblocích, které výrazně
                                                snižují přenos vibrací do
                                                konstrukce, k níž je uchycen.
                                                Přívodní napájecí kabel může být
                                                veden zespodu či z boku, je tedy
                                                na zákazníkovi, jaká varianta
                                                jeho aplikaci vyhovuje.
                                            </p>

                                            <h3>Kruhové pohony – typ VD-D</h3>
                                            <p>
                                                Kruhové pohony typu VD-D jsou
                                                určeny pro vibrační podavače,
                                                které pracují s těžkými díly
                                                nebo s díly, které vyžadují
                                                složitou orientaci. Vyznačují se
                                                především jednoduchou
                                                konstrukcí, která umožňuje
                                                snadný přístup k jednotlivým
                                                komponentám. Montážní otvory pro
                                                uchycení k základní desce jsou
                                                přístupné shora, čímž je
                                                usnadněno uchycení ke stroji
                                                nebo na jiné pracovní místo.
                                                Celý pohon stojí na pryžových
                                                nohách, které výrazně snižují
                                                přenos vibrací do konstrukce. Z
                                                důvodu vyšší hmotnosti a síle
                                                vibrací doporučujeme umístit na
                                                dostatečně tuhou základnu stroje
                                                nebo na samostatnou konstrukci.
                                                Přívodní napájecí kabel může být
                                                veden zespodu či z boku, je tedy
                                                na zákazníkovi, jaká varianta
                                                jeho aplikaci vyhovuje.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div id="pr1-1" className="pr">
                                    sdfsdfsdfdsfsf usdf
                                </div> */}

                                {/* Linearni podavace */}
                                <div
                                    id="linearni-podavace"
                                    className="pr mt-0 pt-0 prod-item text-justify"
                                >
                                    {/* <div className="row prod-header">
                                        <div className="col-8">
                                            <h1 className="pb-3">
                                                <FormattedHTMLMessage id="products.prod2" />
                                            </h1>
                                            <p>
                                                Lineární podavače slouží k
                                                dopravě již zorientovaných dílů
                                                do odběrného místa nebo místa
                                                navazující operace. Skládají se
                                                z pohonu a lineární lišty,
                                                kterou jsou díly dopravovány od
                                                výstupu z kruhového podavače do
                                                navazujícího místa montáže či
                                                odběru. Lineární podavače mohou
                                                být řazeny za sebou a lze tak
                                                vytvořit téměř nekonečně dlouhou
                                                dráhu.
                                            </p>
                                        </div>
                                        <div className="col">
                                            <Img
                                                fluid={
                                                    this.props.data
                                                        .linearni_pohon_trans
                                                        .childImageSharp.fluid
                                                }
                                                className=""
                                                alt=""
                                            />
                                        </div>
                                    </div> */}


<div className="row">
                                        <div className="col">
                                            <h1 className="pb-3">
                                                <FormattedHTMLMessage id="products.prod2" />
                                            </h1>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="float-right p-3">
                                                <Img
                                                    fixed={
                                                        this.props.data
                                                            .linearni_pohon_trans
                                                            .childImageSharp
                                                            .fixed
                                                    }
                                                    className=""
                                                    alt=""
                                                />
                                            </div>

                                            <p>
                                                Lineární podavače slouží k
                                                dopravě již zorientovaných dílů
                                                do odběrného místa nebo místa
                                                navazující operace. Skládají se
                                                z pohonu a lineární lišty,
                                                kterou jsou díly dopravovány od
                                                výstupu z kruhového podavače do
                                                navazujícího místa montáže či
                                                odběru. Lineární podavače mohou
                                                být řazeny za sebou a lze tak
                                                vytvořit téměř nekonečně dlouhou
                                                dráhu.
                                            </p>
                                      
                                        </div>
                                    </div>



                                </div>

                                {/* Predzasobniky */}
                                <div id="predzasobniky" className="prod-item">
                                    <div className="row prod-header">
                                        <div className="col-8">
                                            <h1 className="pb-3">
                                                <FormattedHTMLMessage id="products.prod3" />
                                            </h1>
                                            <p>Popis v přípavě.</p>
                                        </div>
                                        <div className="col">
                                            <Img
                                                fluid={
                                                    this.props.data
                                                        .kruhovy_pohon_trans
                                                        .childImageSharp.fluid
                                                }
                                                className=""
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Odhlučňovací kryty */}
                                <div
                                    id="odhlucnovaci-kryty"
                                    className="prod-item"
                                >
                                    <div className="row prod-header">
                                        <div className="col-8">
                                            <h1 className="pb-3">
                                                <FormattedHTMLMessage id="products.prod4" />
                                            </h1>
                                            <p>Popis v přípavě.</p>
                                        </div>
                                        <div className="col">
                                            <Img
                                                fluid={
                                                    this.props.data
                                                        .odhlucnovaciKryt
                                                        .childImageSharp.fluid
                                                }
                                                className=""
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Řízení a regulace */}
                                <div
                                    id="rizeni-a-regulace"
                                    className="prod-item"
                                >
                                    <div className="row prod-header">
                                        <div className="col-8">
                                            <h1 className="pb-3">
                                                <FormattedHTMLMessage id="products.prod5" />
                                            </h1>
                                            <p>Popis v přípavě.</p>
                                        </div>
                                        <div className="col">
                                            <Img
                                                fluid={
                                                    this.props.data
                                                        .rizeniRegulace
                                                        .childImageSharp.fluid
                                                }
                                                className=""
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Doplňující prvky */}
                                <div
                                    id="doplnujici-prvky"
                                    className="prod-item"
                                >
                                    <div className="row prod-header">
                                        <div className="col-8">
                                            <h1 className="pb-3">
                                                <FormattedHTMLMessage id="products.prod6" />
                                            </h1>
                                            <p>Popis v přípavě.</p>
                                        </div>
                                        <div className="col">
                                            <Img
                                                fluid={
                                                    this.props.data
                                                        .dopravnikTrans
                                                        .childImageSharp.fluid
                                                }
                                                className=""
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}

ProductsPage.propTypes = {
    intl: intlShape.isRequired,
}

export default compose(withPageContext, withLayout, injectIntl)(ProductsPage)
